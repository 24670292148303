import React from 'react';
//eslint-disable-next-line import/no-unresolved
import img from '@package/components/layout/images/ausl-fe-sfondo-ricerca.jpeg?width=1400';

const Background = (props) => (
  <div
    className="searchSections-background"
    {...props}
    style={{ backgroundImage: `url(${img})` }}
  />
);

export default Background;
