/**
 * Add your config changes here.
 * @module config
 */
import bookSVG from '@plone/volto/icons/book.svg';
import RubricaView from '@package/components/Blocks/Rubrica/View.jsx';
import RubricaEdit from '@package/components/Blocks/Rubrica/Edit.jsx';
import FarmaciaView from '@package/components/View/FarmaciaView/FarmaciaView.jsx';
import farmacieSVG from '@plone/volto/icons/first-aids.svg';
import SearchFarmaciaView from '@package/components/Blocks/SearchFarmacia/View.jsx';
import SearchFarmaciaEdit from '@package/components/Blocks/SearchFarmacia/Edit.jsx';
import Head from '@package/components/Head.jsx';
import applyItaliaConfig from '@italia/config/italiaConfig';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    matomoSiteId: '1e0GNbaq6l',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: Head,
      },
    ],
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'Servizio Sanitario Regionale Emilia-Romagna',
      siteSubtitle: 'Azienda Unità Sanitaria Locale di Ferrara',
      parentSiteTitle: 'Regione Emilia-Romagna',
      parentSiteURL: 'https://www.regione.emilia-romagna.it/',
      subsiteParentSiteTitle: 'Servizio Sanitario Regionale Emilia-Romagna',
      arLoginUrl: 'https://www.ausl.fe.it/area-dipendenti',
      arLogoutUrl: '/logout',
      markSpecialLinks: false,
      // smallFooterLinks: {
      //   default: [{ title: 'Mappa del sito', url: '/sitemap' }],
      //   it: [{ title: 'Mappa del sito', url: '/it/sitemap' }],
      //   en: [{ title: 'Sitemap', url: '/en/sitemap' }],
      // },
    },
  };

  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Farmacia: FarmaciaView,
  };

  // config.widgets = {
  //   ...config.widgets,
  // };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      // {
      //   id: 'cardWithSideImageTemplate',
      //   isDefault: false,
      //   title: 'Card con immagine affiancata',
      //   template: CardWithSideImageTemplate,
      //   skeleton: CardWithSideImageTemplateSkeleton,
      //   schemaEnhancer: ({ schema, formData, intl }) => {
      //     let pos = addDefaultOptions(schema, formData, intl);
      //     addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
      //     return schema;
      //   },
      // },
    ],
    listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
    listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  };

  config.blocks.blocksConfig.rubrica = {
    id: 'rubrica',
    title: 'Rubrica',
    icon: bookSVG,
    group: 'search',
    view: RubricaView,
    edit: RubricaEdit,
    restricted: false,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 0,
  };

  config.blocks.blocksConfig.searchFarmacia = {
    id: 'searchFarmacia',
    title: 'Ricerca farmacie', // ricerca turni o ferie farmacie
    icon: farmacieSVG,
    group: 'search',
    view: SearchFarmaciaView,
    edit: SearchFarmaciaEdit,
    restricted: false,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  };

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2022-12-16T18:00:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
              fr: {
                title: 'Matomo',
                description:
                  "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
              },
            },
          },
          // ...config.settings[
          //   'volto-gdpr-privacy'
          // ].defaultPanelConfig.technical.choices.filter(
          //   (f) => f.config_key !== 'GANALYTICS',
          // ),
        ],
      },
    },
  };

  return config;
}
