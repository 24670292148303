import React, { useEffect, useState } from 'react';

import { Helmet } from '@plone/volto/helpers';

const Head = () => {
  const [installPromptEvent, setInstallPromptEvent] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');

    script.text =
      'if ("serviceWorker" in navigator) {console.log("Will the service worker register?");navigator.serviceWorker.register("serviceWorker.js").then(function(reg){console.log("Yes, it did.");}).catch(function(err) {console.log("No it didn\'t. This happened:", err) });}  ';
    document.head.appendChild(script);

    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent Chrome <= 67 from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      setInstallPromptEvent(event);
      // Update the install UI to notify the user app can be installed
      //document.querySelector('#install-button').disabled = false;
    });
    return () => {
      // clean up the script when the component in unmounted
      document.head.removeChild(script);
    };
  }, []);

  const install = () => {
    installPromptEvent.prompt();
    installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome === 'accepted') {
        //console.log('User accepted the A2HS prompt');
        // Clear the saved prompt since it can't be used again
        setInstallPromptEvent(null);
      } else {
        //console.log('User dismissed the A2HS prompt');
      }
    });
  };

  return (
    <>
      <Helmet>
        <link rel="manifest" href="site.webmanifest" />
        <meta name="msapplication-TileColor" content="#0f034f" />
        <meta name="msapplication-TileImage" content="/mstile-150x150.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      {installPromptEvent && (
        <div className="install-app">
          <div className="install-title">Vuoi installare l'app di AuslFE? </div>
          <div className="install-actions">
            <button
              className="install-later btn btn-black btn-sm"
              onClick={() => {
                setInstallPromptEvent(null);
              }}
            >
              Più tardi
            </button>
            <button
              className="install-button btn btn-success btn-sm"
              onClick={() => {
                install();
              }}
            >
              Installa
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Head;
