/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'design-react-kit/dist/design-react-kit';
import { Pagination } from '@italia/components/ItaliaTheme';
import { getFarmacia } from '@package/actions';

import SearchBar from './SearchBar';
import SearchSorting from './SearchSorting';
import Results from './Results';

const Body = ({ isEditMode, data }) => {
  const dispatch = useDispatch();
  const resultsRef = createRef();
  const searchType = data?.search_type; // type of search, Ferie o Turni ('shifts' or 'vacations')
  const b_size = 10; // number of page results to show
  const [currentPage, setCurrentPage] = useState(0);

  const [sorting, setSorting] = useState();
  const [filters, setFilters] = useState({});
  const [filtersOptions, setFiltersOptions] = useState({
    aree_territoriali: [],
    comuni: [],
    lista_localita: [],
  });

  const [results, setResults] = useState([]);
  const [resultsPage, setResultsPage] = useState([]);

  const listAreeTerritoriali = [
    {
      value: 1,
      label: 'Argenta',
    },
    {
      value: 2,
      label: 'Bondeno',
    },
    {
      value: 3,
      label:
        'Cento, Sant`Agostino, Mirabello, Poggio Renatico, Vigarano Mainarda',
    },
    {
      value: 4,
      label: 'Comacchio, Codigoro, Lagosanto, Goro, Mesola',
    },
    {
      value: 5,
      label: 'Copparo, Jolanda di Savoia, Riva del Po, Tresignana',
    },
    {
      value: 6,
      label: 'Portomaggiore, Masi Torello, Ostellato, Fiscaglia, Voghiera',
    },
    {
      value: 7,
      label: 'Ferrara',
    },
  ];

  const loading = useSelector((state) => {
    return state.farmacia?.loading || false;
  });
  const searchFarmacia = useSelector((state) => {
    return state.farmacia?.result;
  });

  /* Initial search */
  const doSearch = () => {
    dispatch(getFarmacia());
  };

  useEffect(() => {
    doSearch();
  }, [searchType]);

  /* Search with filters */
  const doFilter = (filters, items, searchType) => {
    let newResults = items ?? [];

    // Filtro testo libero
    if (filters.searchableText?.length > 0) {
      newResults = items.filter((item) =>
        item.title.toLowerCase().includes(filters.searchableText.toLowerCase()),
      );
    }

    // Turni - filtro Data
    if (searchType === 'shifts' && filters.date && items?.length > 0) {
      const inputDate = new Date(filters.date).getTime();

      newResults = newResults.filter((item) => {
        let checkTurno = item?.turni?.map((turno) => {
          const dalSplit = turno?.dal.split('/');
          const alSplit = turno?.al.split('/');
          const turnoDal = new Date(
            +dalSplit[2],
            dalSplit[1] - 1,
            +dalSplit[0],
          ).getTime();
          const turnoAl = new Date(
            +alSplit[2],
            alSplit[1] - 1,
            +alSplit[0],
          ).getTime();

          return turnoDal <= inputDate && turnoAl >= inputDate ? true : false;
        });
        return checkTurno && checkTurno.includes(true);
      });
    }

    // Turni - filtro Area Territoriale
    if (filters.area_territoriale && filters.area_territoriale !== null) {
      newResults = newResults.filter(
        // TO DO: sostituire con === quando da backend verrà restituito il numero esatto della select da dettaglio Farmacia
        // eslint-disable-next-line eqeqeq
        (item) => item.area_territoriale == filters.area_territoriale.value,
      );
    }

    // Ferie - filtro Comune
    if (filters.comune && filters.comune !== null) {
      newResults = newResults.filter(
        (item) =>
          item.comune.toLowerCase() === filters.comune.value.toLowerCase(),
      );
    }

    // Ferie - filtro Località
    if (filters.localita && filters.localita !== null) {
      newResults = newResults.filter(
        (item) =>
          item.localita.toLowerCase() === filters.localita.value.toLowerCase(),
      );
    }

    // set results
    setResults(newResults);

    // set sorting
    if (!sorting) {
      setSorting('comune');
    }

    // set currentPage
    if (newResults.length > b_size) {
      setCurrentPage(1);
    } else if (newResults.length <= b_size) {
      setCurrentPage(0);
    }
  };

  const doDebouncedFilter = useCallback(debounce(doFilter, 400), []);

  useEffect(() => {
    let options = { comuni: [], lista_localita: [] };
    let tempResults = [];
    let comuniOptions = [];
    let localitaOptions = [];

    if (searchFarmacia.items) {
      // set results
      tempResults = searchFarmacia.items;

      // create filters Area Territoriale, Comune and Località
      options.aree_territoriali = listAreeTerritoriali;
      comuniOptions = [
        ...new Set(searchFarmacia.items.map((item) => item.comune).sort()),
      ];
      localitaOptions = [
        ...new Set(searchFarmacia.items.map((item) => item.localita).sort()),
      ];
    }
    createSelectOptions(comuniOptions, options.comuni);
    createSelectOptions(localitaOptions, options.lista_localita);

    setFiltersOptions(options);
    setResults(tempResults);
    if (tempResults.length > 0) {
      setCurrentPage(1);
    }

    // set defaults date and change results
    setFilters({ date: new Date().toISOString() });
  }, [searchFarmacia]);

  // check if select Comune is empty or not
  const checkClearComune = (inputComune) => {
    if (searchFarmacia.items?.length > 0) {
      let resultsByComune = searchFarmacia.items;
      let options = { comuni: [], lista_localita: [] };
      let localitaOptions = [];

      if (inputComune === null) {
        localitaOptions = [
          ...new Set(searchFarmacia.items.map((item) => item.localita).sort()),
        ];
        createSelectOptions(localitaOptions, options.lista_localita);
        setFiltersOptions({
          ...filtersOptions,
          lista_localita: options.lista_localita,
        });
      } else {
        resultsByComune = resultsByComune.filter(
          (item) =>
            item.comune.toLowerCase() === inputComune.value.toLowerCase(),
        );
        localitaOptions = [
          ...new Set(resultsByComune.map((item) => item.localita).sort()),
        ];
        createSelectOptions(localitaOptions, options.lista_localita);
        setFiltersOptions({
          ...filtersOptions,
          lista_localita: options.lista_localita,
        });
      }
    }
  };

  // function to create object select options
  function createSelectOptions(arrayOptions, selectOptions) {
    // eslint-disable-next-line no-unused-expressions
    arrayOptions?.forEach((option) => {
      selectOptions.push({
        value: option.toLowerCase(),
        label: option,
      });
    });
  }

  // trigger for filters
  useEffect(() => {
    if (searchFarmacia?.items) {
      doDebouncedFilter(filters, searchFarmacia.items, searchType);
    }
  }, [filters]);

  // pagination function
  function onPaginationChange(e, { activePage }) {
    resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = activePage?.children ?? 1;
    setCurrentPage(current);
  }

  // trigger for current page and results
  useEffect(() => {
    if (currentPage !== 0 && results?.length > 0) {
      let start = b_size * (currentPage - 1);
      let end = b_size * currentPage;
      setResultsPage(results.slice(start, end));
    } else {
      setResultsPage(results.slice(0, b_size));
    }
  }, [currentPage, results]);

  // trigger for sorting change
  useEffect(() => {
    if (sorting && results?.length > 0) {
      const resultsSorted = [...results];
      sortByInput(resultsSorted, sorting);
      setResults(resultsSorted);

      setCurrentPage(1);
    }
  }, [sorting]);

  // sort function
  const sortByInput = (items, value) => {
    items.sort((a, b) => {
      if (a[value] < b[value]) {
        return -1;
      }
      if (a[value] > b[value]) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <div
      className={cx('', {
        'public-ui': isEditMode,
      })}
    >
      <div className="block-search-farmacia">
        <SearchBar
          isEditMode={isEditMode}
          setFilters={setFilters}
          searchType={searchType}
          filters={filters}
          options={filtersOptions}
          checkClearComune={checkClearComune}
        />
        <SearchSorting
          setSorting={setSorting}
          sorting={sorting}
          isEditMode={isEditMode}
        />

        {!loading ? (
          <>
            <Results
              items={resultsPage}
              isEditMode={isEditMode}
              searchType={searchType}
              resRef={resultsRef}
            />
            {/* Paginazione */}
            {results?.length > b_size && (
              <div className="pagination-wrapper">
                <Pagination
                  key={new Date().getTime()}
                  activePage={currentPage}
                  totalPages={Math.ceil(results?.length / b_size)}
                  onPageChange={onPaginationChange}
                />
              </div>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <Spinner active />
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;
